export const SearchRouteData = [
  // Employee routes (unchanged)
  { control: 'employee', name: "Dashboard", path: "/employee/dashboard" },
  { control: 'employee', name: "Education", path: "/employee/:id/education" },
  { control: 'employee', name: "Family", path: "/employee/:id/family-info" },
  { control: 'employee', name: "Experience", path: "/employee/:id/work-experience" },
  { control: 'employee', name: "Documents", path: "/employee/document" },
  { control: 'employee', name: "Personal Info", path: "/employee/:id/personal-info" },
  { control: 'employee', name: "Leave Application", path: "/employee/:id/leave-application-emp" },
  { control: 'employee', name: "My Attendance", path: "/employee/MyAttendance" },
  { control: 'employee', name: "Active Task", path: "/employee/activeTask" },
  { control: 'employee', name: "Task Complete", path: "/employee/taskcomplete" },
  { control: 'employee', name: "Task Reject", path: "/employee/taskreject" },
  { control: 'employee', name: "Birthday", path: "/employee/:id/birthday" },
  { control: 'employee', name: "Notification", path: "/employee/notification" },
  { control: 'employee', name: "New Task", path: "/employee/newTask" },

  // Manager routes (unchanged)
  { control: 'manager', name: "Dashboard", path: "/manager/dashboard" },
  { control: 'manager', name: "Leave Application", path: "/manager/leaveApplication" },
  { control: 'manager', name: "Create Leave", path: "/manager/createLeave" },
  { control: 'manager', name: "My Attendance", path: "/manager/myAttendance" },
  { control: 'manager', name: "New Task", path: "/manager/newTask" },
  { control: 'manager', name: "Active Task", path: "/manager/ActiveTask" },
  { control: 'manager', name: "Cancelled Task", path: "/manager/taskcancle" },
  { control: 'manager', name: "Completed Task", path: "/manager/taskcomplete" },
  { control: 'manager', name: "Rejected Task", path: "/manager/rejectTask" },
  { control: 'manager', name: "Notification", path: "/manager/notification" },
  { control: 'manager', name: "Personal Info", path: "/manager/personal-info" },

  // HR routes (unchanged)
  { control: 'hr', name: "Employee", path: "/hr/employee" },
  { control: 'hr', name: "Salary", path: "/hr/salary" },
  { control: 'hr', name: "Company", path: "/hr/company" },
  { control: 'hr', name: "Role", path: "/hr/role" },
  { control: 'hr', name: "Position", path: "/hr/position" },
  { control: 'hr', name: "Department", path: "/hr/department" },
  { control: 'hr', name: "Country", path: "/hr/country" },
  { control: 'hr', name: "State", path: "/hr/state" },
  { control: 'hr', name: "City", path: "/hr/city" },
  { control: 'hr', name: "Leave Application", path: "/hr/leaveApplication" },
  { control: 'hr', name: "Assign Leave", path: "/hr/assignLeave" },
  { control: 'hr', name: "All Employee Leaves", path: "/hr/allEmpLeave" },
  { control: 'hr', name: "Dashboard", path: "/hr/dashboard" },
  { control: 'hr', name: "New Task", path: "/hr/newTask" },
  { control: 'hr', name: "Active Task", path: "/hr/ActiveTask" },
  { control: 'hr', name: "Task Cancel", path: "/hr/taskcancle" },
  { control: 'hr', name: "Task Complete", path: "/hr/taskcomplete" },
  { control: 'hr', name: "Reject Task", path: "/hr/rejectTask" },
  { control: 'hr', name: "Attendance", path: "/hr/attenDance" },
  { control: 'hr', name: "View Attendance", path: "/hr/viewAttenDance" },
  { control: 'hr', name: "Attendance Register", path: "/hr/AttendanceRegister" },
  { control: 'hr', name: "Holiday", path: "/hr/holiday" },
  { control: 'hr', name: "Today's Attendance", path: "/hr/todaysAttendance" },
  { control: 'hr', name: "Create Leave", path: "/hr/createLeave" },
  { control: 'hr', name: "Leave Accepted", path: "/hr/leaveAccepted" },
  { control: 'hr', name: "Leave Rejected", path: "/hr/leaveRejected" },
  { control: 'hr', name: "Notification", path: "/hr/notification" },
  { control: 'hr', name: "Manual Attendance", path: "/hr/manualAttand" },
  { control: 'hr', name: "Personal Info", path: "/hr/personal-info" },

  // Updated Admin routes
  { control: 'admin', name: "Dashboard", path: "/admin/dashboard" },
  { control: 'admin', name: "Role", path: "/admin/role" },
  { control: 'admin', name: "Position", path: "/admin/position" },
  { control: 'admin', name: "Department", path: "/admin/department" },
  { control: 'admin', name: "Portal Master", path: "/admin/portal-master" },
  { control: 'admin', name: "Project Bid", path: "/admin/project-bid" },
  { control: 'admin', name: "Salary", path: "/admin/salary" },
  { control: 'admin', name: "Employee", path: "/admin/employee" },
  { control: 'admin', name: "Task", path: "/admin/task" },
  { control: 'admin', name: "Task Assign", path: "/admin/taskassign" },
  { control: 'admin', name: "Task Cancel", path: "/admin/taskcancle" },
  { control: 'admin', name: "Task Complete", path: "/admin/taskcomplete" },
  { control: 'admin', name: "Task Reject", path: "/admin/taskreject" },
  { control: 'admin', name: "Task Active", path: "/admin/taskActive" },
  // { control: 'admin', name: "Admin Attendance", path: "/admin/adminAttendance" },
  { control: 'admin', name: "Employee Attendance", path: "/admin/viewAttendance" },
  { control: 'admin', name: "Today's Attendance", path: "/admin/todaysAttendance" },
  { control: 'admin', name: "My Attendance", path: "/admin/myAttendance" },
  // { control: 'admin', name: "Apply Leave", path: "/admin/applyLeave" },
  { control: 'admin', name: "All Employee Leaves", path: "/admin/AllEmpLeave" },
  { control: 'admin', name: "Leave Assign", path: "/admin/leaveAssign" },
  { control: 'admin', name: "Leave Accepted", path: "/admin/leaveAccepted" },
  { control: 'admin', name: "Leave Application", path: "/admin/leaveApplication" },
  { control: 'admin', name: "Leave Rejected", path: "/admin/leaveRejected" },
  { control: 'admin', name: "Notification", path: "/admin/notification" },
  { control: 'admin', name: "Notice Management", path: "/admin/NoticeManagement" },
  // { control: 'admin', name: "Add Team", path: "/admin/addteam" },
  // { control: 'admin', name: "Teams", path: "/admin/teams" },
  // { control: 'admin', name: "Team", path: "/admin/team/:id" },
];
